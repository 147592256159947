import React from "react";
import { Helmet } from "react-helmet";
import { Shield, Info, Users, Lock, Bell, FileText } from "lucide-react";
import Header from "../Header";
import { Link } from "react-router-dom";

function PolicySection({ icon, title, content }) {
  return (
    <div className="flex space-x-4">
      <div className="flex-shrink-0 mt-1">{icon}</div>
      <div>
        <h2 className="text-xl font-semibold mb-2">{title}</h2>
        <p className="text-gray-700 ">{content}</p>
      </div>
    </div>
  );
}

function PrivacyPolicy() {
  return (
    <div>
      <Helmet>
        <title>Privacy Policy - Biodata Makers</title>
        <meta
          name="description"
          content="BiodataMakers is your ultimate solution for creating professional, customizable biodata online for both job applications and marriage purposes. With an extensive library of beautifully designed templates, our platform simplifies the process of crafting a standout biodata that leaves a lasting impression. Whether you’re a job seeker aiming to showcase your qualifications or preparing a marriage biodata to highlight your personality and values, BiodataMakers has got you covered. Enjoy seamless customization, instant PDF downloads, and easy sharing options—all tailored to meet your specific needs. Take the hassle out of biodata creation and start your journey toward success with BiodataMakers today."
        />
        <meta
          name="keywords"
          content="privacy policy,create biodata, data collection, data usage, personal information, security, updates, communication"
        />
        <link rel="canonical" href="https://www.biodatamakers.com/policy" />
      </Helmet>
      <Header />

      <div className="min-h-screen" style={{ paddingTop: "100px" }}>
        <main className="flex-grow container mx-auto px-4 py-12">
          <div className="max-w-4xl mx-auto space-y-12">
            <div className="text-center space-y-4">
              <h1 className="text-4xl font-bold tracking-tighter sm:text-5xl md:text-6xl bg-clip-text text-transparent bg-gradient-to-r from-gray-900 to-gray-900 text-gray-900">
                Privacy Policy
              </h1>
              <p className="text-xl text-gray-600 dark:text-gray-400">
                We care about your privacy and are committed to protecting your
                personal data.
              </p>
            </div>

            <section className="space-y-8">
              <PolicySection
                icon={<Info className="h-6 w-6 text-blue-500" />}
                title="Information We Collect"
                content="We may collect personal information, including but not limited to, your name, contact information, and any other details you voluntarily provide when using our biodata-making web service."
              />

              <PolicySection
                icon={<Users className="h-6 w-6 text-purple-500" />}
                title="How We Use Your Information"
                content="The primary purpose of collecting your information is to create biodata documents based on the details you provide. We may use aggregated and anonymized data for the improvement of our service, including website functionality and user experience."
              />

              <PolicySection
                icon={<FileText className="h-6 w-6 text-pink-500" />}
                title="Your Choices"
                content="You have the right to access, correct, or delete your personal information. You can manage your preferences through your account settings or by contacting us."
              />

              <PolicySection
                icon={<Lock className="h-6 w-6 text-green-500" />}
                title="Security"
                content="We implement reasonable security measures to protect your information from unauthorized access, disclosure, alteration, and destruction."
              />

              <PolicySection
                icon={<Shield className="h-6 w-6 text-yellow-500" />}
                title="Changes to this Privacy Policy"
                content="We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the date of the latest revision will be indicated."
              />

              <PolicySection
                icon={<Bell className="h-6 w-6 text-red-500" />}
                title="Communication"
                content="We may use your contact information to send you updates, notifications, and important information related to our service."
              />
            </section>
          </div>
        </main>

        <footer className="site-footer">
          <div className="container">
            <div className="footer-content">
              <div className="footer-section about">
                <h3 className="section-title">About</h3>
                <p>
                  Introducing the importance of a well-structured biodata PDF
                  can make a significant positive impression on employers or
                  others reviewing it. Remember to use a friendly and engaging
                  tone throughout the blog post, and feel free to customize the
                  content based on your target audience and the specific biodata
                  creation tool you're recommending.
                </p>
              </div>
              <div style={{ marginBottom: "30px" }}>
                <div className="support">
                  <h3 className="section-title">Support</h3>
                  <ul className="link-list">
                    <li>
                      <Link
                        className="nav-link scrollto"
                        to="/blog"
                        style={{ color: "black" }}
                      >
                        Blog
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        className="nav-link scrollto"
                        to="/term"
                        style={{ color: "black" }}
                      >
                        Term
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        className="nav-link scrollto"
                        to="/policy"
                        style={{ color: "black" }}
                      >
                        Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="nav-link scrollto"
                        to="/template"
                        style={{ color: "black" }}
                      >
                        Template
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-section links">
                <div className="links-flex">
                  <div className="contact">
                    <h3 className="section-title">Contact With Us</h3>
                    <ul className="contact-list">
                      <li>
                        <a
                          href="mailto:biodatamakers1@gmail.com"
                          className="contact-item"
                        >
                          <i className="fa-solid fa-envelope"></i>
                          <p className="contact-text" style={{ color: "black" }}>
                            biodatamakers1@gmail.com
                          </p>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.biodatamakers.com"
                          className="contact-item"
                        >
                          <i className="fa-solid fa-globe"></i>
                          <p className="contact-text" style={{ color: "black" }}>
                            www.biodatamakers.com
                          </p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
