import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import Home from "./pages/home";
import "./assets/css/responsive.css";
import MakeBioData from "./pages/makeBioData";
import Templates from "./pages/templates";
import Template from "./pages/templates/Demotempllate";
import Term from "./components/term";
import ErroPage from "./pages/errorPage";
import Blog from "./components/Blog/blog";
import Policy from "./components/Policy";

const App = () => {
  return (
    <>
      <Helmet>
        <title>Create Your Marriage Biodata | BiodataMakers</title>
        <meta
          name="description"
          content="BiodataMakers is your ultimate solution for creating professional, customizable biodata online for both job applications and marriage purposes. With an extensive library of beautifully designed templates, our platform simplifies the process of crafting a standout biodata that leaves a lasting impression. Whether you’re a job seeker aiming to showcase your qualifications or preparing a marriage biodata to highlight your personality and values, BiodataMakers has got you covered. Enjoy seamless customization, instant PDF downloads, and easy sharing options—all tailored to meet your specific needs. Take the hassle out of biodata creation and start your journey toward success with BiodataMakers today."
        />

        <meta
          name="keywords"
          content="biodata maker, online biodata creator, professional biodata templates, marriage biodata generator, job biodata format, biodata samples PDF, easy biodata creation, custom biodata maker, free biodata templates, biodata builder, resume generator, CV templates, biodata download online, marriage biodata format, create biodata online free, job biodata templates, professional biodata examples, biodata for marriage PDF, best biodata generator, biodata formats and designs"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="BiodataMakers" />
        <link
          rel="canonical"
          href="https://www.biodatamakers.com/biodata-make"
        />
        <meta
          property="og:title"
          content="Create Your Marriage Biodata | BiodataMakers"
        />
        <meta
          property="og:description"
          content="Create your professional marriage biodata easily with BiodataMakers. Choose from a variety of templates, customize your biodata, and download in PDF format."
        />
        <meta
          property="og:url"
          content="https://www.biodatamakers.com/biodata-make"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.biodatamakers.com/assets/images/biodata_creation.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Create Your Marriage Biodata | BiodataMakers"
        />
        <meta
          name="twitter:description"
          content="Create your professional marriage biodata easily with BiodataMakers. Choose from a variety of templates, customize your biodata, and download in PDF format."
        />
        <meta
          name="twitter:image"
          content="https://www.biodatamakers.com/assets/images/biodata_creation.png"
        />
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/biodata-make" element={<MakeBioData />} />
          <Route path="/template" element={<Template />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/templates" element={<Templates />} />
          <Route path="/term" element={<Term />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="*" element={<ErroPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
