import React from "react";
import "./index.css";
import App from "./App";
import { hydrate, render } from "react-dom";

const rootElement = document.getElementById("root");

const renderApp = () => (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

if (rootElement) {
  if (rootElement.hasChildNodes()) {
    hydrate(renderApp(), rootElement);
  } else {
    render(renderApp(), rootElement);
  }
}
